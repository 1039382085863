/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/react-in-jsx-scope */

const el = require('../../../lib/utils/jsx_component');
const {
  favHeartBanner,
  favFolder,
  favClose
} = require('../../../lib/assets/icons');
const {
  ylkObjToString
} = require('../../../lib/utils/rapid');
const baseRapid = {
  sec: 'favorites',
  itc: 1,
  subsec: 'add-folder'
};
const add_modal = () => el("div", {
  className: "favorites-add-dialog favorites-dialog favorites-both"
}, el("div", null, el("div", {
  className: "favorites-dialog-title-wrap"
}, el("div", {
  className: "favorites-dialog-title"
}, "ADD"), el("div", {
  className: "fav-close-icon-wrap"
}, el("a", {
  className: "favorites-dialog-close-button navicon-fav-close",
  title: "Close",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'add-folderclose',
    elm: 'icon'
  })
}, el("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  viewBox: "0 0 256 256"
}, el("path", {
  fill: "#444",
  d: favClose
}))))), el("div", {
  className: "favorites-dialog-content nobtmbdr auth clearfix"
}, el("div", {
  className: "addicon lft_bdr"
}, el("a", {
  id: "addbookmark",
  className: "auth addbookmark",
  title: "Add a favorite",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    elm: 'btn'
  })
}, el("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 256 256",
  height: "80px",
  width: "80px"
}, el("g", {
  fill: "#454545"
}, el("path", {
  d: favHeartBanner
}))), el("div", {
  className: "favorites-icon-label"
}, "Add a favorite"))), el("div", {
  className: "addicon"
}, el("a", {
  id: "addfolder",
  className: "auth addfolder",
  title: "Add a folder",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    elm: 'btn'
  })
}, el("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 256 256",
  height: "80px",
  width: "80px"
}, el("path", {
  fill: "#454545",
  d: favFolder
})), el("span", {
  className: "favorites-icon-label"
}, "Add a folder"))))));
module.exports = add_modal;