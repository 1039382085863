/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/react-in-jsx-scope */
const el = require('../../../lib/utils/jsx_component');
const {
  favClose
} = require('../../../lib/assets/icons');
const {
  ylkObjToString
} = require('../../../lib/utils/rapid');
const baseRapid = {
  sec: 'favorites',
  itc: 1,
  subsec: 'edit-favorite'
};
const edit_favorite_modal = () => el("div", {
  className: "favorites-edit-dialog favorites-dialog favorites-bookmark"
}, el("div", null, el("a", {
  className: "favorites-dialog-close-button navicon-fav-close",
  title: "Close",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'edit-favoriteclose',
    elm: 'icon'
  })
}, el("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  viewBox: "0 0 256 256"
}, el("path", {
  fill: "#444",
  d: favClose
}))), el("h2", {
  className: "favorites-dialog-title"
}, "Edit Favorite"), el("form", {
  id: "favorites-form-edtbk",
  onSubmit: "return false",
  method: "POST"
}, el("div", {
  className: "favorites-dialog-content clearfix"
}, el("label", {
  htmlFor: "favorites-input-name"
}, "Title"), el("input", {
  type: "text",
  className: "favorites-input",
  id: "favorites-input-name",
  maxLength: "100"
}), el("label", {
  htmlFor: "favorites-input-url"
}, "URL"), el("input", {
  type: "text",
  className: "favorites-input",
  id: "favorites-input-url"
}), el("label", {
  htmlFor: "favorites-input-selector"
}, "Location"), el("input", {
  type: "text",
  className: "selector-search-input selector-text-input",
  id: "favorites-input-selector"
})), el("div", {
  className: "favorites-dialog-message"
}, el("span", {
  className: "favorites-dialog-message-icon"
})), el("div", {
  className: "favorites-dialog-buttons"
}, el("a", {
  id: "",
  className: "favorites-delete-button fav-edtbk-dialog-delete",
  title: "Delete this favorite",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'delete',
    elm: 'bkmk-del'
  })
}, "Delete"), el("a", {
  id: "",
  className: "favorites-cancel-button fav-edtbk-dialog-cancel",
  title: "Cancel this edit",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'edit-favoriteclose',
    elm: 'btn'
  })
}, "Cancel"), el("a", {
  id: "",
  className: "favorites-save-button fav-edtbk-dialog-save",
  title: "Save this edit",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'edit-favoriteconfirm',
    elm: 'bkmk-edit'
  })
}, "Save")))));
module.exports = edit_favorite_modal;