/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/react-in-jsx-scope */
const el = require('../../../lib/utils/jsx_component');
const {
  favClose
} = require('../../../lib/assets/icons');
const {
  ylkObjToString
} = require('../../../lib/utils/rapid');
const baseRapid = {
  sec: 'favorites',
  itc: 1,
  subsec: 'edit-folder'
};
const edit_folder_modal = () => el("div", {
  className: "favorites-edit-dialog favorites-dialog favorites-folder"
}, el("div", null, el("a", {
  className: "favorites-dialog-close-button navicon-fav-close",
  title: "Close",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'edit-folderclose',
    elm: 'icon'
  })
}, el("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  viewBox: "0 0 256 256"
}, el("path", {
  fill: "#444",
  d: favClose
}))), el("h2", {
  className: "favorites-dialog-title"
}, "Edit Folder"), el("form", {
  id: "favorites-form-edtfdr",
  onSubmit: "return false",
  method: "POST"
}, el("div", {
  className: "favorites-dialog-content clearfix"
}, el("label", {
  htmlFor: "favorites-input-name"
}, "Title"), el("input", {
  type: "text",
  className: "favorites-input",
  id: "favorites-input-name",
  maxLength: "100"
}), el("label", {
  htmlFor: "favorites-input-selector"
}, "Location"), el("input", {
  type: "text",
  className: "selector-search-input selector-text-input",
  id: "favorites-input-selector"
}), el("div", {
  className: "favorites-dialog-message"
}, el("span", {
  className: "favorites-dialog-message-icon"
}))), el("div", {
  className: "favorites-dialog-buttons"
}, el("a", {
  id: "",
  className: "favorites-delete-button fav-edtfdr-delete-button",
  title: "Delete this folder",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'delete',
    elm: 'folder-del'
  })
}, "Delete"), el("a", {
  id: "",
  className: "favorites-cancel-button fav-edtfdr-dialog-cancel",
  title: "Cancel this edit",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'edit-folderclose',
    elm: 'btn'
  })
}, "Cancel"), el("a", {
  id: "",
  className: "favorites-save-button fav-edtfdr-dialog-save",
  title: "Save this edit",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'edit-folderconfirm',
    elm: 'folder-edit'
  })
}, "Save")))));
module.exports = edit_folder_modal;