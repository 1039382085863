/* eslint-disable */
/**
 * FolderSelector jQuery plugin.
 *
 * @depends jQuery 1.5.2
 */

;(function($) {
  $.fn.folderSelector = function(customOptions) {
    var defaultOptions = {
      folderData: {}, // { title:'Folder', id:0, children:[] }
      parentNode: null,
      startId: null,
      currentId: null,
      folderMap: null,
      baseUrl: '',
      showToolbar: true,
      showAddUI: true,
      showMenuButton: true,
      startExpanded: false,
      timeout: 2000,
      maxFolderDepth: 10,
      maxFolderNameLength: 100,

      getFolderData: null,
      addFolderData: null,

      cloneObject: function cloneObject(obj) {
        return $.parseJSON(JSON.stringify(obj))
      },

      processData: function(folderData) {
        var map = {}

        // Normalize incoming data
        if ($.isArray(folderData)) {
          folderData = {
            title: '',
            id: '_top',
            children: folderData
          }
        } else if (folderData == null || $.isEmptyObject(folderData)) {
          folderData = {
            title: '',
            id: '_top',
            children: []
          }
        }

        function process(data, parent) {
          data.parent = parent

          // Assign the first object as the top, or root, object
          if (!map.hasOwnProperty('_top')) {
            map['_top'] = data
          }

          if (data.children && data.children.length) {
            $.each(data.children, function update(i, obj) {
              process(obj, data.id)
            })
          }

          map[data.id] = data
        }

        process(folderData)
        return map
      },

      strings: {
        searchInputHintText: 'Find a Folder',
        addInputHintText: 'Create a Folder',
        menuButtonTooltip: 'Toggle menu',
        topButtonTooltip: 'Go to top level',
        upButtonTooltip: 'Go up a level',
        addButtonTooltip: 'Add folder',
        addButtonLabel: 'ADD',
        folderItemTooltip: 'Select this item',
        emptysubfdr: 'Currently, there are no folders under "_FOLDERNAME_"'
      }
    }

    var folderSelector = function(customOptions) {
      var options = $.extend(true, {}, defaultOptions, customOptions),
        $container = null,
        $menu = null,
        $menuButton = null,
        $menuItems = null,
        $toolbar = null,
        $input = null,
        $addInput = null,
        namespace = 'aol-selector',
        count = 0,
        topId = null,
        selectedId = null,
        currentId = null,
        folderMap = null,
        favoritesItems = null,
        init = function() {
          if (!options.elem) return

          if (options.elem.nodeName == 'INPUT') {
            $input = $(options.elem).wrap('<div>')
            $container = $input.parent()
          } else {
            $container = $(options.elem)
            $input = $('<input type="text">').appendTo($container)
          }

          $container.addClass('selector-container')

          $input.removeClass().addClass('selector-search-input selector-text-input')

          if (options.showMenuButton) {
            $menuButton = $(
              '<a class="selector-menu-button navicon-down" data-ylk="itc:1;slk:folders-toggle;elm:dropdown"  title="' +
                getString('menuButtonTooltip') +
                '"></a>'
            )
              .appendTo($container)
              .bind('click', function() {
                toggleMenu()
              })
              .bind('keyup', function(e) {
                if (e.keyCode == 13) {
                  toggleMenu()
                  return false
                }
              })
          }

          $menu = $('<div class="selector-menu">').appendTo($container)

          $menuItems = $('<ul class="selector-menu-items">').appendTo($menu)

          if (options.showToolbar) {
            $toolbar = $('<div class="selector-toolbar selector-top-level clearfix">')
              .append(
                '<a class="selector-top-button navicon-left-double " data-ylk="itc:1;slk:folder-top;elm:icon" title="' +
                  getString('topButtonTooltip') +
                  '"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 512 512"><g fill="#444"><path d="M255.109 80.057L79.1 256.065l176.009 176.009-39.551 39.549L0 256.065 215.558 40.508z"/><path d="M510.218 80.057L334.209 256.065l176.009 176.009-39.55 39.549-215.559-215.558L470.668 40.508z"/></g></svg></a>'
              )
              .append(
                '<a class="selector-up-button navicon-left " data-ylk="itc:1;slk:folder-back;elm:icon" title="' +
                  getString('upButtonTooltip') +
                  '"><svg xmlns="http://www.w3.org/2000/svg" fill="#444" height="12" width="12" viewBox="-240 -120 512 256"><path d="M-134 20.9l253.3 251.6 37-37.1L-58.2 20.9 166-203.4l-37-37.1z"/></svg></a>'
              )
              .appendTo($menu)

            if (options.showAddUI) {
              $toolbar
                .append(
                  '<input type="text" class="selector-add-input selector-text-input" maxlength="' +
                    options.maxFolderNameLength +
                    '" placeholder="' +
                    getString('addInputHintText') +
                    '" />'
                )
                .append(
                  '<a class="selector-add-button disabled" data-ylk="itc:1;slk:folder-add;elm:icon"title="' +
                    getString('addButtonTooltip') +
                    '">' +
                    getString('addButtonLabel') +
                    '</a>'
                )
            }

            $addInput = $toolbar.find('.selector-add-input')
            $addInput.bind('keyup', onAddInputEdit)

            $toolbar.find('.selector-top-button').bind('click', function() {
              selectTopFolder()
            })
            $toolbar.find('.selector-up-button').bind('click', function() {
              selectParentFolder(selectedId)
            })
            $toolbar.find('.selector-add-button').bind('click', function() {
              hideErrorAlert()

              var title = $addInput.val()

              // Sanitization
              if (title && title != '') {
                title = title.replace(/\&\#?[\w]+;/gi, '') // Strip all other HTML entities
                title = title.replace(/<[^>]+>/gi, '') // Strip HTML tags
                title = $.trim(title) // Trim and collapse whitespace
                title = title.replace(/\s+/g, ' ')
              }
              // Validation
              if (title && title != '') {
                addFolderData(title, selectedId)
              } else {
                showErrorAlert(getString('errorInvalidTitle'))
              }
            })
            $toolbar.find('input.selector-add-input').bind('keyup', function() {
              if ($(this).val() == '') {
                $toolbar.find('a.selector-add-button').addClass('disabled')
              } else {
                $toolbar.find('a.selector-add-button').removeClass('disabled')
              }
            })
          }

          folderMap = options.folderMap || options.processData(options.folderData)
          topId = folderMap['_top'].id
          currentId = parseInt(options.currentId)

          favoritesItems = options.favoritesItems

          var startId = options.startId != null ? options.startId : topId

          selectFolder(startId)

          $menu.toggle(options.startExpanded)
        },
        htmlEncode = function(string) {
          return $('<div/>')
            .text(string)
            .html()
        },
        getString = function(name) {
          return options.strings[String(name)] || ''
        },
        selectFolder = function(folderId) {
          //if (selectedId != folderId) {

          var folderObj = folderMap[folderId] || {}

          function selectFolderByObj(folderObj) {
            if (folderObj) {
              selectedId = folderObj.id

              // Set the input value to the current folder's title
              $input.val(folderObj.title)

              // Store selected id in DOM attribute, so can be parsed
              $input.attr('data-selected-id', folderObj.id)

              renderMenu(folderObj)

              var isTop = folderObj.parent == null
              $toolbar.toggleClass('selector-top-level', isTop)

              // Show/hide add UI based on whether we've reached the max folder depth'
              var level = getFolderLevel(folderObj.id)
              toggleAddUI(level < options.maxFolderDepth, getString('addFolderDisabledText'))

              $(document).trigger({
                type: 'item-selected.' + namespace,
                itemData: folderObj
              })
              //show empty text and update folder list height if number of folders is less than 4
              updateSubForderListHeight(folderObj)
            }
          }

          // If folder exists and has children array, use current data
          if (folderObj && folderObj.hasOwnProperty('children') && folderObj.children.length != 0) {
            selectFolderByObj(folderObj)

            // Else request new folder data
          } else {
            getFolderData(
              folderId,
              function(data, jqXHR) {
                if (data) {
                  // Update current folder obj
                  folderObj = $.extend(folderObj, data)

                  // Add children to folderMap
                  function processChildren(obj) {
                    if (obj.children && obj.children.length) {
                      $.each(obj.children, function(i, child) {
                        child.parent = obj.id
                        folderMap[child.id] = child

                        processChildren(child)
                      })
                    } else {
                      delete obj.children
                    }
                  }
                  processChildren(folderObj)

                  $container.trigger({
                    type: 'folder-data-updated.' + namespace,
                    folderMap: folderMap
                  })

                  selectFolderByObj(folderObj)
                }
              },
              function(textStatus, jqXHR) {
                // Error
              },
              getFolderPath(folderId)
            )
          }
          //}
        },
        getFolderPath = function(id) {
          var path = ''

          function getPath(id, path) {
            path = path || ''
            var obj = folderMap[id]
            if (obj && obj.parent != null) {
              path = '/' + encodeURIComponent(obj.title || '') + path
              path = getPath(obj.parent, path)
            }
            return path
          }

          return getPath(id) || '/'
        },
        getFolderLevel = function(id) {
          var level = 0

          function getLevel(id) {
            var obj = folderMap[id]
            if (obj && obj.parent != null) {
              level++
              getLevel(obj.parent)
            }
          }
          getLevel(id)

          return level
        },
        getFolderData = function(id, successFunc, errorFunc, folderPath) {
          if (options.getFolderData) {
            options.getFolderData(id, successFunc, errorFunc, folderPath)
          }
        },
        selectTopFolder = function() {
          selectFolder(topId)
        },
        selectParentFolder = function(folderId) {
          if (folderId && folderMap[folderId].parent != null) {
            selectFolder(folderMap[folderId].parent)
          }
        },
        addFolderData = function(title, parentId) {
          var self = this
          if (options.addFolderData) {
            options.addFolderData(
              title,
              parentId,
              function(data, jqXHR) {
                if (data) {
                  $addInput.val('')
                  $toolbar.find('.selector-add-button').addClass('disabled')

                  function adapt(item) {
                    if (item.name != null) item.title = item.name

                    if (item.updated_at != null) item.modTime = item.updated_at

                    if (item.type != null) {
                      if (item.type == 'folder') item.type = 2
                      else if (item.type == 'item') item.type = 1
                    }

                    if (item.parent_id != null) {
                      item.parent = item.parent_id
                      delete item['parent_id']
                    }

                    if (item.parent == null) {
                      item.parent = 0
                    }

                    if (item.children == null) {
                      item.children = []
                    }
                    delete item['name']
                    delete item['updated_at']
                  }

                  function findItemById(items, id, itemPath) {
                    var returnObject = {
                      itemPath: null,
                      item: null
                    }
                    if (items != null && items.length > 0) {
                      for (var i = 0; i < items.length; ++i) {
                        var item = items[i]
                        itemPath.push(item)

                        if (item.id == id) {
                          returnObject.item = item
                          returnObject.itemPath = itemPath
                          return returnObject
                        } else {
                          var subReturnedObject = findItemById(item.children, id, itemPath)
                          if (subReturnedObject.item != null) {
                            return subReturnedObject
                          }
                        }

                        itemPath.pop()
                      }
                    }

                    return returnObject
                  }

                  adapt(data)
                  folderMap[data.id] = data

                  if (data.parent == 0) {
                    favoritesItems.unshift(data)
                  } else {
                    var parentItemObject = findItemById(favoritesItems, data.parent, [])
                    if (parentItemObject.item != null) {
                      if (parentItemObject.item.children == null)
                        parentItemObject.item.children = new Array()
                      parentItemObject.item.children.unshift(data)
                    }
                  }

                  if (folderMap[data.parent].children == null) {
                    folderMap[data.parent].children = new Array()
                  }

                  var existed = false
                  for (var i = 0; i < folderMap[data.parent].children.length; ++i) {
                    var c = folderMap[data.parent].children[i]
                    if (c.type == data.type && c.id == data.id) {
                      existed = true
                      break
                    }
                  }
                  if (!existed) folderMap[data.parent].children.unshift(options.cloneObject(data))

                  //selectFolder( data.id );
                  selectFolder(data.parent)
                  $(document).trigger({
                    type: 'folder-added.' + namespace,
                    itemData: data
                  })
                }
              },
              function(textStatus, jqXHR) {
                // Error
              }
            )
          }
        },
        renderMenu = function(folder) {
          $menuItems.empty()

          if (folder && folder.children) {
            $.each(folder.children, function(i, child) {
              if (child.id != currentId && child.type == 2) {
                var item = $(
                  '<li class="selector-menu-item" title="' +
                    getString('folderItemTooltip') +
                    '" id="s' +
                    (child.id || '') +
                    '"><a data-ylk="itc:1;elm:link" href="#" >' +
                    htmlEncode(child.title) +
                    '</a></li>'
                )
                if (child.children && child.children.length) {
                  //item.append('<span class="selector-menu-item-down-arrow">&gt;</span>');
                }
                item.appendTo($menuItems)

                item.bind('click', function() {
                  selectFolder(
                    $(this)
                      .attr('id')
                      .substr(1)
                  )
                })
              }
            })
          }
        },
        toggleMenu = function() {
          var visible = !$menu.is(':hidden')
          var delta = $menu.outerHeight() * (visible ? 1 : -1)

          $container.trigger({
            type: 'menu-toggle-start.' + namespace,
            visible: visible,
            delta: delta
          })

          $menu.slideToggle({
            duration: 150,
            easing: 'swing',
            complete: function() {
              $container.trigger({
                type: 'menu-toggle-end.' + namespace,
                visible: !visible,
                delta: delta
              })
              if (!visible) $menuButton.addClass('menuopen')
              else $menuButton.removeClass('menuopen')
            }
          })
        },
        toggleAddUI = function(enabled, text) {
          if ($addInput.length) {
            $toolbar.find('.selector-add-button').toggleClass('selector-disabled', !enabled)
            $addInput.toggleClass('selector-disabled', !enabled)

            $toolbar.find('.selector-toolbar-message').remove()
            if (!enabled && text) {
              $toolbar.append('<span class="selector-toolbar-message">' + text + '</span>')
            }

            hideErrorAlert()
          }
        },
        onAddInputEdit = function(event) {
          var title = $addInput.val()

          hideErrorAlert()
        },
        showErrorAlert = function(message) {
          $addInput.addClass('selector-error')
          $container.trigger({
            type: 'selector-error.' + namespace,
            error: message
          })
        },
        hideErrorAlert = function() {
          if ($addInput.hasClass('selector-error')) {
            $addInput.removeClass('selector-error')
            $container.trigger({
              type: 'selector-error.' + namespace,
              error: null
            })
          }
        },
        updateSubForderListHeight = function(folderObj) {
          var ulElm = $('ul.selector-menu-items')
          var nItem = $('ul.selector-menu-items li').length
          var nHeight = nItem * 24
          var mheight = 0
          if (nItem == 0) {
            var txt =
              '<li class="subempty">' +
              getString('emptysubfdr').replace('_FOLDERNAME_', htmlEncode(folderObj.title)) +
              '</li>'
            ulElm.append(txt)
            nItem = 1
          }
          if (nItem > 4) {
            nItem = 4
          }

          var sum = 0
          $('ul.selector-menu-items li:lt(' + nItem + ')').each(function() {
            mheight = $(this).height()
            if (mheight < 1) {
              //to get the height of hidden element
              $('.selector-menu').show()
              mheight = $(this).height()
              $('.selector-menu').hide()
            }
            sum += mheight + 8
          })
          if (nItem > 4) {
            nHeight = Math.max(mheight, sum)
          } else {
            nHeight = sum
          }
        }

      init()
    }

    return this.each(function() {
      customOptions = customOptions || {}
      customOptions.elem = this
      folderSelector(customOptions)
    })
  }
})(jQuery)
