/* eslint-disable */
/*!
 * AOL.com utilities
 * @author: Andy Kahn [andy.kahn@teamaol.com]
 */

'use strict';

/**
 * Module dependencies.
 * @private
 * TODO: Maybe we put components into an index file to import and dynamically intialize
 */

/**
 * Utilities prototype.
 */

var utils = exports = module.exports = {};

utils.config = {
  'https': (location.protocol === 'https:')
};

/**
 * Check existance of nested properties
 * @example:
  var test = {level1:{level2:{level3:'level3'}} };
  utils.checkNested(test, 'level1', 'level2', 'level3'); // true
  utils.checkNested(test, 'level1', 'level2', 'foo'); // false
 */
utils.checkNested = function checkNested(obj) {
  var args = Array.prototype.slice.call(arguments, 1);

  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
};

utils.findClass = function (node, match) {
    if (node == null || node.className == null || typeof (node.className) === 'undefined') {
        return '';
    }
    var clss = node.className;
    var clssArr = clss.split(' ');
    for (var i = 0, tot = clssArr.length; i < tot; i++) {
        if (clssArr[i].match(match)) {
            return clssArr[i];
        }
    }
    return '';
};

utils.getUrlParamByName = function(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'), results = regex.exec(location.search);

  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/**
 * Debounce function
 * @description Returns a function, that, as long as it continues to be invoked, will not
   be triggered. The function will be called after it stops being called for N milliseconds.
   If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
 */
utils.debounce = function debounce(fn, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) fn.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait || 150);
    if (callNow) fn.apply(context, args);
  };
};

/**
  * Poll function
  * @example:
  * // Usage:  ensure element is visible
    utils.poll(function() {
      return $('html').hasClass('foo');
    }, 2000, 150);
  */
utils.poll = function poll(fn, timeout, interval) {
  var dfd = $.Deferred();
  var endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  (function p() {
    // If the condition is met, we're done!
    if(fn()) {
      dfd.resolve();
    }
    // If the condition isn't met but the timeout hasn't elapsed, go again
    else if (Number(new Date()) < endTime) {
      setTimeout(p, interval);
    }
    // Didn't match and too much time, reject!
    else {
      dfd.reject(new Error('timed out for ' + fn + ': ' + arguments));
    }
  })();

  return dfd.promise();
};

function is(str, type) {
  return typeof str === type;
}

utils.isObject = function isObject(obj) {
  return obj !== null && is(obj, 'object');
};

utils.isString = function isString(obj) {
  return is(obj, 'string');
};

utils.isFunction = function isFunction(obj) {
  return is(obj, 'function');
};

utils.contains = function contains(haystack, needle) {
  return haystack && haystack.indexOf(needle) !== -1;
};

utils.resolveUrl = function resolveUrl(url) {
  if (utils.config.https) {
    url = url.replace('http://o.aolcdn.com/', 'https://s.aolcdn.com/');
    url = url.replace('http://s.huffpost.com/', 'https://secure.huffingtonpost.com/');
    url = url.replace('http://api.bit.ly/', 'https://api-ssl.bit.ly/');
  }
  return url;
};

utils.uniqueId = function uniqueId(prefix) {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  var id = s4() + '-' + s4() + '-' + s4() + '-' + s4();
  return prefix ? prefix + id : id;
};

utils.isiPad = function() {
  return (navigator.platform.indexOf('iPad') != -1);
};

utils.isiOS = function() {
  var device = navigator.userAgent.toLowerCase();
  return device.match(/(iphone|ipod|ipad)/);
};

utils.isElementInViewport = function(el, allowPartiallyInView, threshold) {
  threshold = (typeof threshold === 'number') ? threshold : 0;
  allowPartiallyInView = (typeof allowPartiallyInView === 'boolean') ? allowPartiallyInView : false;
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  var rect = el.getBoundingClientRect(),
      windowHeight = (window.innerHeight || document.documentElement.clientHeight),
      windowWidth  = (window.innerWidth || document.documentElement.clientWidth);
  if (allowPartiallyInView) {
    return (
      ((0 + threshold) >= rect.top - windowHeight || (0 + threshold) >= rect.bottom - windowHeight) &&
      (rect.left >= (0 + threshold) || rect.right <= windowWidth)
    );
  } else {
    return (
      rect.top >= (0 + threshold) &&
      rect.left >= (0 + threshold) &&
      rect.bottom <= windowHeight &&
      rect.right <= windowWidth
    );
  }
};

function comscoreUserConsent(cb) {
  if (window && window.__cmp) {
    if (window.__uspapi) {
      window.__uspapi('getDoNotSell', 1, function(data, success) {
        if (success && typeof data.doNotSell === 'boolean') {
          cb(data.doNotSell ? "0" : "1")
        } else {
          cb(false)
        }
      })
    } else {
      cb(false)
    }
  } else {
    cb(false)
  }
}

utils.comScoreBeacon = function() {
  if (window && window.__cmp) {
    window.__cmp('getVendorConsents', null, function(result, isSuccess) {
      result = result || {};
      var vendorConsents = result.vendorConsents;
      // gdprApplies specifies whether the user is in EU jurisdiction.
      // vendor id 77 is ComScore.
      if (isSuccess && result && result.gdprApplies && vendorConsents && typeof(vendorConsents['77']) === 'boolean') {
        COMSCORE.beacon({ c1: "2", c2: "1000009", cs_ucfr: result.vendorConsents['77'] ? "1" : "0" })
        window.localStorage.setItem('cs_ucfr_value', result.vendorConsents['77'] ? "1" : "0")
      } else {
        comscoreUserConsent(function(doNotSell) {
          if (doNotSell) {
            COMSCORE.beacon({ c1: "2", c2: "1000009", cs_ucfr: doNotSell })
            window.localStorage.setItem('cs_ucfr_value', doNotSell)
          } else {
            COMSCORE.beacon({ c1: "2", c2: "1000009" })
            window.localStorage.setItem('cs_ucfr_value', 'null')
          }
        })
      }
    });
  } else {
    if (window.localStorage.getItem('cs_ucfr_value') === 'null') {
      COMSCORE.beacon({ c1: "2", c2: "1000009" })
    } else {
      COMSCORE.beacon({ c1: "2", c2: "1000009", cs_ucfr: window.localStorage.getItem('cs_ucfr_value') })
    }
  }
};

utils.sanitizeInput = function(input) {
  if (input && input != '') {
    // Substitute common HTML eneities with characters
    input = input.replace('&amp;', '&');
    input = input.replace('&apos;', '\'');
    input = input.replace('&quot;', '"');
    input = input.replace('&lt;', '<');
    input = input.replace('&gt;', '>');
    input = input.replace('&nbsp;', ' ');

    input = input.replace(/\&\#?[\w]+;/ig, ''); // Strip all other HTML entities

    input = input.replace(/<[^>]+>/ig, ''); // Strip HTML tags

    // Trim and collapse whitespace
    input = $.trim(input);
    input = input.replace(/\s+/g, ' ');
  }
  return input;
};
