/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/react-in-jsx-scope */
const el = require('../../../lib/utils/jsx_component');
const {
  favClose
} = require('../../../lib/assets/icons');
const {
  ylkObjToString
} = require('../../../lib/utils/rapid');
const baseRapid = {
  sec: 'favorites',
  itc: 1,
  subsec: 'add-favorite'
};
const save_favorites_modal = () => el("div", {
  className: "favorites-saved-dialog favorites-dialog favorites-bookmark"
}, el("div", null, el("a", {
  className: "favorites-dialog-close-button navicon-fav-close",
  title: "Close",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'save-favoriteclose',
    elm: 'icon'
  })
}, el("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  viewBox: "0 0 256 256"
}, el("path", {
  fill: "#444",
  d: favClose
}))), el("h2", {
  className: "favorites-dialog-title"
}, "SAVED!"), el("form", {
  id: "favorites-form-save",
  onSubmit: "return false",
  method: "POST"
}, el("div", {
  className: "favorites-dialog-content clearfix"
}, "This item has been successfully added to your ", el("b", null, "_FOLDERNAME_"), " folder."), el("div", {
  className: "favorites-dialog-buttons"
}, el("input", {
  type: "checkbox",
  className: "favorites-savebk-chkbox fav-chkbox",
  "data-ylk": "sec:favorites;itc:1;elm:intent-check;slk:donotshow-dialog;subsec:add-favorite",
  id: "favorites-input-checkbox",
  value: ""
}), el("label", {
  className: "favorites-input-checkbox-label",
  htmlFor: "favorites-input-checkbox"
}, "Do not show this dialogue again."), el("a", {
  id: "",
  className: "favorites-confirmsave-button fav-savebk-dialog-cnfsave fnp f-0",
  title: "Add Another Favorite",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'Add Another Favorite',
    elm: 'bkmk-add'
  })
}, "Add Another Favorite")))));
module.exports = save_favorites_modal;