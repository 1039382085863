/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/react-in-jsx-scope */
const el = require('../../../lib/utils/jsx_component');
const {
  favClose
} = require('../../../lib/assets/icons');
const {
  ylkObjToString
} = require('../../../lib/utils/rapid');
const baseRapid = {
  sec: 'favorites',
  itc: 1,
  subsec: 'delete-favorite'
};
const delete_favorite_modal = () => el("div", {
  className: "favorites-delete-dialog favorites-dialog favorites-bookmark"
}, el("div", null, el("a", {
  className: "favorites-dialog-close-button navicon-fav-close",
  title: "Close",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'delete-favoriteclose',
    elm: 'icon'
  })
}, el("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  viewBox: "0 0 256 256"
}, el("path", {
  fill: "#444",
  d: favClose
}))), el("h2", {
  className: "favorites-dialog-title"
}, "DELETE"), el("form", {
  id: "favorites-form-delbk",
  onSubmit: "return false",
  method: "POST"
}, el("div", {
  className: "favorites-dialog-content clearfix"
}, "Are you sure you want to delete ", el("b", null, "_BOOKMARKNAME_"), "?"), el("div", {
  className: "favorites-dialog-buttons"
}, el("a", {
  id: "",
  className: "favorites-cancel-button fav-delbk-dialog-cancel",
  title: "Cancel this delete",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'delete-favoriteclose',
    elm: 'btn'
  })
}, "Cancel"), el("a", {
  id: "",
  className: "favorites-save-button fav-delbk-dialog-delete ",
  title: "Confirm this delete",
  href: "#",
  "data-ylk": ylkObjToString({
    ...baseRapid,
    slk: 'delete-favoriteconfirm',
    elm: 'bkmk-delete'
  })
}, "Delete")))));
module.exports = delete_favorite_modal;